export default() => {

  function expandMenu() {
    const expandTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-behavior="expand-menu-trigger"]');

    expandTriggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        const closing = trigger.getAttribute('data-trigger-state') === 'open';

        if (!closing) {
          //clse all other menus when this one opens
          closeOpen();
        }
        toggleMenu(trigger);
      });
    })

    function closeOpen() {
      const expandedTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-trigger-state="open"]');

      expandedTriggers.forEach(trigger => {
        toggleMenu(trigger);
      })
    }

    function toggleMenu(trigger) {
      const menuId = trigger.getAttribute('aria-controls'),
      wrapperId = trigger.getAttribute('data-trigger-wrapper'),
      closing = trigger.getAttribute('data-trigger-state') === 'open',
      menu = document.getElementById(menuId),
      wrapper = document.getElementById(wrapperId);

      trigger.classList.toggle('morphed');
      trigger.setAttribute('aria-expanded', closing ? 'false' : 'true');
      trigger.setAttribute('data-trigger-state', closing ? 'closed' : 'open');
      menu.classList.toggle('open');
      menu.setAttribute('aria-hidden', closing ? 'true' : 'false');
      wrapper.classList.toggle('menu-open');
    }
  }

  document.addEventListener('DOMContentLoaded', () => {
    expandMenu();
  });
}
