
export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const fakeList: NodeListOf<HTMLElement> = document.querySelectorAll('[data-behavior="fakeCheck"]');
    const eventFakeCheck = new Event("fakeCheck");

    fakeList.forEach((fake) => {

      var realChecks = fake.parentNode.querySelectorAll<HTMLInputElement>('input.real_checkbox') as NodeListOf<HTMLInputElement> | null;;
      var realCheck: HTMLInputElement;

      // Find the real checkbox
      if (realChecks) {
        realChecks.forEach(check => {
          realCheck = check;
        });
      }

      if (realCheck) {
        realCheck.checked = fake.classList.contains('active');

        // on UI toggle the checked property of the real checkbox and the state of the fake one
        fake.addEventListener('click', () => {
          fakeCheck(fake, realCheck);
        })
        fake.addEventListener('keyup', (event) => {
          if (event.key == 'Enter') {
            fakeCheck(fake, realCheck);
          }
        });
      }
    });

    function fakeCheck(fakeCheck, realCheck) {
      realCheck.dispatchEvent(eventFakeCheck);
      fakeCheck.dispatchEvent(eventFakeCheck);
      realCheck.checked = !realCheck.checked;
      fakeCheck.classList.toggle('active');
    }
  });

}
