export default () => {
  window.addEventListener("DOMContentLoaded", () => {
    console.log("initialize user select forms");
    const forms: NodeListOf<HTMLElement> = document.querySelectorAll('[data-behavior="userSelectNewsletterForm"]');

    let i = 0;

    [].forEach.call(forms, (form) => {
      function formUpdate(msg) {
        form.innerHTML = msg;
      }
      function createError(name, msg) {
        removeError(name);
        let l = document.createElement("label");
        l.classList.add("error");
        l.setAttribute("for", name);
        l.setAttribute("id", name + "-error");
        l.setAttribute("data-behavior", "inline-error");
        l.innerHTML = msg;
        return l;
      }
      function removeError(name) {
        let error = form.querySelector("#" + name + "-error");
        if (error) {
          error.remove();
        }
      }
      function removeErrors() {
        let errors = form.querySelectorAll('[data-behavior="inline-error"');
        [].forEach.call(errors, (error) => {
          error.remove();
        });
      }

      i++;

      const eleCheckWrap = form.querySelector('[data-behavior="userSelectNewsletterList"]') as HTMLDivElement | null;
      const eleChecks = form.querySelectorAll("input[type=checkbox]") as NodeListOf<HTMLInputElement> | null;
      const eleInputs = form.querySelectorAll('[data-behavior="userSelectNewsletterInput"]') as NodeListOf<HTMLInputElement> | null;
      const eleEmail = form.querySelector("input[type=email]") as HTMLInputElement | null;
      const wrapSubmit = form.querySelector('[data-behavior="userSelectNewsletterSubmit"]');

      eleEmail.setAttribute("id", "user-select-email-" + i);
      eleCheckWrap.setAttribute("id", "user-select-newsletters-list-" + i);

      [].forEach.call(eleChecks, (check) => {
        check.addEventListener(
          "fakeCheck",
          () => {
            removeError(eleCheckWrap.id);
          },
          false
        );
      });

      eleEmail.addEventListener("focus", () => {
        removeError(eleEmail.id);
      });

      form.addEventListener("submit", (e) => {
        e.preventDefault();

        removeErrors();

        // Temporary email validation
        var hasError = false;
        if (!eleEmail.value.match(/(.+)@(.+){2,}\.(.+){2,}/)) {
          eleCheckWrap.after(createError(eleEmail.id, "Email is required and must contain @ and ."));
          hasError = true;
        }

        var hasChecked = false;
        eleChecks.forEach((checkbox) => {
          // Do something with each checkbox
          if (checkbox.checked) {
            hasChecked = true;
            return;
          }
        });

        if (!hasChecked) {
          eleCheckWrap.after(createError(eleCheckWrap.id, "Please subscribe to at least one Newsletter."));
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        eleCheckWrap.style.display = "none";

        eleInputs.forEach((input) => {
          input.style.display = "none";
        });

        if (wrapSubmit) {
          wrapSubmit.classList.toggle("loading");
        }

        import("./reCaptchaValidate").then(({ default: validate }) => {
          validate(form.dataset.placement)
            .then((result) => {
              import("./processEmailForm").then(({ default: processEmailForm }) => {
                processEmailForm(form)
                  .then((result) => {
                    formUpdate('<p class="signup-thankyou">Thanks for signing up!</p>');
                  })
                  .catch((result) => {
                    formUpdate('<p class="signup-error"><span>Sorry.</span> There was an error submitting your email.  Please contact customer service to sign up.</p>');
                    console.log(result);
                  });
              });
            })
            .catch((result) => {
              formUpdate('<p class="signup-error">Spammer Detected!</p>');
              console.log(result);
            });
        });
      });
    });
  });
};
